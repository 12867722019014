import React, { useContext } from "react";
import Slider from "react-slick";

import { AppContext } from "../../../../AppHooks";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./AwardsNRecognitions.scss";

const AwardsNRecognitions = ({ awards }) => {
  const appContext = useContext(AppContext);
  const isMobile = appContext.displayType.get() === "mobile";
  const isTablet = appContext.displayType.get() !== "desktop";
  const sortedAwards = [...awards].sort((a, b) => a?.acf?.sort_in_the_list - b?.acf?.sort_in_the_list);

  const getSliderSettings = () => {
    let settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 6,
      slidesToScroll: 1,
      slidesPerRow: 1,
      arrows: true,
    };

    if (isMobile) {
      return (settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        slidesPerRow: 1,
        arrows: true,
      });
    }

    if (isTablet) {
      return (settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        slidesPerRow: 1,
        arrows: true,
      });
    }

    return settings;
  };

  if (!sortedAwards.length) return null;

  return (
    <div className="awards_n_recognitions">
      <h2 className="awards_n_recognitions-header">Awards & Recognition</h2>
      <div className="awards_n_recognitions-content">
        <div>
          <Slider {...getSliderSettings()}>
            {(sortedAwards || []).map((item, index) => (
              <a
                href={item?.acf?.url}
                target="blank"
                key={`slide-${index}`}
                className="awards_n_recognitions-slide"
                rel={item?.acf?.link_type === "dofollow" ? "" : "nofollow"}
              >
                <picture>
                  {(isTablet || isMobile) && Boolean(item.image_compress) && (
                    <source srcSet={item.image_compress} type="image/png" />
                  )}
                  {Boolean(item?.image_webp) && <source srcSet={item.image_webp} type="image/webp" />}
                  <source srcSet={item?.acf?.image} type="image/png" />
                  <img src="image.png" alt={item?.title?.rendered} />
                </picture>
              </a>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default AwardsNRecognitions;
