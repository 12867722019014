import React from "react";
import "./index.scss";

const VideoModal = () => (
  <div className="iframe-wrapper">
    <iframe
      title="video"
      src="https://www.youtube.com/embed/JyUcPAfR_4M?autoplay=1&rel=0&color=white"
      allowFullScreen
    />
  </div>
);

export default VideoModal;
