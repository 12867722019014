import React, { useContext } from "react";
import { AppContext } from "../../../../AppHooks";

import Slider from "react-slick";
import UContainer from "../../../../components/UContainer/UContainer";

import "./NPowerful.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const NPowerful = () => {
  const list = [
    {
      icon: "/assets/images/homepage/powerful/Reliability.svg",
      label: "Reliability &<br>Expertise",
    },
    {
      icon: "/assets/images/homepage/powerful/Smooth.svg",
      label: "Smooth<br>Communication",
    },
    {
      icon: "/assets/images/homepage/powerful/Rapid.svg",
      label: "Rapid Team<br>Assembling",
    },
    {
      icon: "/assets/images/homepage/powerful/Loyalty.svg",
      label: "Loyalty to<br>the Client",
    },
    {
      icon: "/assets/images/homepage/powerful/Solid.svg",
      label: "Solid<br>Reputation",
    },
    {
      icon: "/assets/images/homepage/powerful/Fast.svg",
      label: "Fast Feedback &<br>Response",
    },
  ];

  const appContext = useContext(AppContext);
  const isTablet = appContext.displayType.get() !== "desktop";

  const getSliderSettings = () => {
    let settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      arrows: true,
    };

    return settings;
  };

  return (
    <div className="n-powerful">
      <UContainer>
        <div className="n-powerful-block">
          <h2 className="n-powerful-header">What Makes Powercode Software Developers Company Powerful? </h2>
          <div className="n-powerful-list">
            {isTablet ? (
              <Slider {...getSliderSettings()}>
                {(list || []).map(({ icon, label }, index) => (
                  <div className={"n-powerful-list-item"} key={index}>
                    <img src={icon} alt="icon" />
                    <p dangerouslySetInnerHTML={{ __html: label }}></p>
                  </div>
                ))}
              </Slider>
            ) : (
              (list || []).map(({ icon, label }, index) => (
                <div className={"n-powerful-list-item"} key={index}>
                  <img src={icon} alt="icon" />
                  <p dangerouslySetInnerHTML={{ __html: label }}></p>
                </div>
              ))
            )}
          </div>
        </div>
      </UContainer>
    </div>
  );
};

export default NPowerful;
