import React, { useContext } from "react";
import { AppContext } from "../../../../AppHooks";

import Slider from "react-slick";
import UContainer from "../../../../components/UContainer/UContainer";

import "./NCardsBottom.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const NCardsBottom = () => {
  const cards = [
    {
      icon: "/assets/images/homepage/Union.svg",
      link: "https://globalcompact.org.ua/",
      label: (
        <p className="n-cards-card-label">
          UN Official
          <br />
          Partner
        </p>
      ),
    },

    {
      icon: "/assets/images/homepage/Union2.svg",
      link: "https://www.who.int/",
      label: (
        <p className="n-cards-card-label">
          Projects for
          <br />
          WHO
        </p>
      ),
    },

    {
      icon: "/assets/images/homepage/Frame3.svg",
      link: "https://profiles.forbes.com/members/business/profile/Vladyslav-Savchenko-Founder-CEO-Powercode/f2fae629-6088-4924-9a26-a32b57f50b4c",
      label: (
        <p className="n-cards-card-label">
          Forbes business
          <br />
          counselling
        </p>
      ),
    },

    {
      icon: "/assets/images/homepage/Toggle.svg",
      link: "https://it-ease.com/en",
      label: (
        <p className="n-cards-card-label">
          Co-founder of
          <br />
          EASE association
        </p>
      ),
    },

    {
      icon: "/assets/images/homepage/computer.svg",
      link: "https://powercode.academy/ru",
      label: (
        <p className="n-cards-card-label">
          IT Academy
          <br />
          owner
        </p>
      ),
    },

    {
      icon: "/assets/images/homepage/bookmarks.svg",
      link: "https://www.instagram.com/stories/highlights/17883154094101646/",
      label: (
        <p className="n-cards-card-label">
          Multi-award
          <br />
          winner
        </p>
      ),
    },

    {
      icon: "/assets/images/homepage/diia.svg",
      link: "https://cyfrovi-mozhlyvosti.powercode.pro",
      label: (
        <p className="n-cards-card-label">
          Collaboration
          <br />
          with Diia
        </p>
      ),
    },
  ];

  const appContext = useContext(AppContext);
  const isMobile = appContext.displayType.get() === "mobile";
  const isTablet = appContext.displayType.get() !== "desktop";

  const getSliderSettings = () => {
    let settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 7,
      slidesToScroll: 1,
      slidesPerRow: 1,
      arrows: false,
    };

    if (isMobile) {
      return (settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        slidesPerRow: 1,
        arrows: true,
      });
    }

    if (isTablet) {
      return (settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        slidesPerRow: 1,
        arrows: true,
      });
    }

    return settings;
  };

  return (
    <div className="n-cards">
      <UContainer>
        <div className="n-cards-block">
          <Slider {...getSliderSettings()}>
            {(cards || []).map(({ icon, label = "", link }, index) => (
              <div className={"n-cards-card"} key={index}>
                {/* eslint-disable react/jsx-no-target-blank */}
                <a href={link} target={"_blank"} rel="nofollow">
                  <img src={icon} alt={label} />
                  {label}
                </a>
              </div>
            ))}
          </Slider>
        </div>
      </UContainer>
    </div>
  );
};

export default NCardsBottom;
