import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import TagsList from "../TagsList/TagsList";
import IconCalendar from "../UIcon/icons/IconCalendar";
import UButton from "../UButton/UButton";
import { formatBlogDate } from "../../helpers/format-blog-date";
import "./blog-article.scss";
import "./blog-article-mob.scss";
import { AppContext } from "../../AppHooks";

export const BlogArticle = props => {
  const history = useHistory();
  const appContext = useContext(AppContext);

  const isTablet = appContext.displayType.get() !== "desktop";

  let classNameInner = ["blog-article"];
  if (props.className) classNameInner.push(props.className);
  classNameInner = classNameInner.join(" ");

  const article = props.article;

  const tags =
    article &&
    article.categories &&
    article.categories.length &&
    article._embedded &&
    article._embedded["wp:term"] &&
    article._embedded["wp:term"][0] &&
    Array.isArray(article._embedded["wp:term"][0])
      ? article._embedded["wp:term"][0].map(el => {
          return {
            name: el.name,
            link: `/blog?category=${el.slug}`,
          };
        })
      : null;

  const link = `/blog/${article.slug}`;
  const handleClick = () => history.push(link);
  return (
    <div className={classNameInner}>
      <div className="blog-article__img-wrap" onClick={handleClick}>
        <picture className="blog-article__img">
          {isTablet && <source srcSet={article?.image_compress} type="image/png" />}
          <source srcSet={article?.image_webp} type="image/webp" />
          <source srcSet={article?.acf?.image} type="image/png" />
          <img src="image.png" alt={article?.acf?.title} />
        </picture>
        <div className="blog-article__img-overlay">
          <UButton secondary={true} className="blog-article__img-overlay-btn">
            View Details
          </UButton>
        </div>
      </div>
      <div className="blog-article__content">
        <h3 className="blog-article__title">
          <Link to={link}>{article?.acf?.title}</Link>
        </h3>
        {tags ? <TagsList items={tags} className="blog-article__tags" /> : null}

        <time dateTime={article.date} className="blog-article__date">
          <IconCalendar />
          <span>{formatBlogDate(article?.date)}</span>
        </time>
      </div>
    </div>
  );
};
