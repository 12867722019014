export const formatBlogDate = date_text => {
  const date = new Date(date_text);

  const month_names = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  return day + " " + month_names[month] + ", " + year;
};
