import React from "react";
import UIcon from "../UIcon";

/* eslint-disable no-unused-vars */
export default function IconCalendar({ children, className = "", ...restProps }) {
  return (
    <UIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      path="M16.2778 2H17.2963C18.4167 2 19.3333 2.9 19.3333 4V18C19.3333 19.1 18.4167 20 17.2963 20H3.03704C1.90648 20 1 19.1 1 18V4C1 2.9 1.90648 2 3.03704 2H4.05556V1C4.05556 0.45 4.51389 0 5.07407 0C5.63426 0 6.09259 0.45 6.09259 1V2H14.2407V1C14.2407 0.45 14.6991 0 15.2593 0C15.8194 0 16.2778 0.45 16.2778 1V2ZM6.09249 9.00106H14.2406C14.8008 9.00106 15.2592 9.45106 15.2592 10.0011C15.2592 10.5511 14.8008 11.0011 14.2406 11.0011H6.09249C5.53231 11.0011 5.07397 10.5511 5.07397 10.0011C5.07397 9.45106 5.53231 9.00106 6.09249 9.00106ZM16.2782 17.9985H4.05595C3.49577 17.9985 3.03743 17.5485 3.03743 16.9985V6.99854H17.2967V16.9985C17.2967 17.5485 16.8384 17.9985 16.2782 17.9985ZM6.09249 13.0016H11.1851C11.7453 13.0016 12.2036 13.4516 12.2036 14.0016C12.2036 14.5516 11.7453 15.0016 11.1851 15.0016H6.09249C5.53231 15.0016 5.07397 14.5516 5.07397 14.0016C5.07397 13.4516 5.53231 13.0016 6.09249 13.0016Z"
      {...restProps}
    />
  );
}
