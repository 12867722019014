import React from "react";
import "./PlayButton.scss";

export default function PlayButton({ as, variant, ...restProps }) {
  const Component = as || "button";
  let additionalClassName = "";
  if (variant) {
    additionalClassName += ` play_button--${variant}`;
  }
  return <Component className={`play_button ${additionalClassName}`} {...restProps} />;
}
