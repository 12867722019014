import React, { useContext, useState } from "react";

import UContainer from "../../../../components/UContainer/UContainer";
import VideoPLayer from "../../../../components/VideoPlayer/VideoPlayer";
import IconArrowDown from "../../../../components/UIcon/icons/IconArrowDown";

import "./NClientsAbout.scss";
import "./NClientsAboutMob.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import Img from "../../../../components/Img/Img";
import { AppContext } from "../../../../AppHooks";

function NextArrow({ slider }) {
  return (
    <button className="n-clients-about__slider-arrow  _next" onClick={() => slider && slider.slickNext()}>
      <IconArrowDown />
    </button>
  );
}

function PrevArrow({ slider }) {
  return (
    <button className="n-clients-about__slider-arrow  _prev" onClick={() => slider && slider.slickPrev()}>
      <IconArrowDown />
    </button>
  );
}

const NClientsAbout = ({ reviewsList }) => {
  const [slider, setSlider] = useState(null);
  const [currentSlideId, setCurrentSlideId] = useState(0);

  const appContext = useContext(AppContext);
  const isMobile = appContext.displayType.get() === "mobile";
  const isTablet = appContext.displayType.get() !== "desktop";

  const settings = {
    infinite: true,
    arrows: false,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: index => {
      setCurrentSlideId(index);
    },
  };

  const videoProps = {
    width: "100%",
    height: "100%",
  };

  return (
    <div className={"n-clients-about"}>
      <UContainer>
        <div className={"n-clients-about__slider"}>
          <h2 className="n-clients-about__title">Clients About Our Software Development Services</h2>
          <div className="n-clients-about__slider-wrap">
            <Slider {...settings} ref={s => setSlider(s)}>
              {((Array.isArray(reviewsList) && reviewsList) || []).map((item, index) => (
                <div className="n-clients-about__slider-content" key={index}>
                  <div className="n-clients-about__slider-main">
                    <p className="n-clients-about__slider-title">{item.acf.text}</p>
                    <div className="n-clients-about__slider-client">
                      <p className="n-clients-about__slider-client-name">{item.title.rendered}</p>
                      <p className="n-clients-about__slider-client-position">{item.acf.position}</p>
                      {item.acf.place ? (
                        <p className="n-clients-about__slider-client-location">
                          <Img src={"/assets/images/partners/school_24px.svg"} alt={item.title.rendered} />
                          <span>{item.acf.place}</span>
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="n-clients-about__slider-video">
                    <VideoPLayer
                      currentSlideId={currentSlideId}
                      key={currentSlideId}
                      src={item.acf.video}
                      controls
                      light={
                        <picture>
                          {(isTablet || isMobile) && Boolean(item.image_compress) && (
                            <source srcSet={item.image_compress} type="image/png" />
                          )}
                          {Boolean(item.image_webp) && <source srcSet={item.image_webp} type="image/webp" />}
                          <source srcSet={item.acf.poster} type="image/png" />
                          <img src="image.png" alt={item.title.rendered} />
                        </picture>
                      }
                      {...videoProps}
                    />
                  </div>
                </div>
              ))}
            </Slider>
            <div className="n-clients-about__slider-nav">
              <div>
                <PrevArrow slider={slider} />
                <NextArrow slider={slider} />
              </div>
            </div>
          </div>
        </div>
      </UContainer>
    </div>
  );
};

export default NClientsAbout;
