import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../../../AppHooks";

import UButton from "../../../../components/UButton/UButton";
import UContainer from "../../../../components/UContainer/UContainer";

import "./NSkilledTeam.scss";

import Slider from "react-slick";
import Img from "../../../../components/Img/Img";

const NSkilledTeam = ({ content }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [slider, setSlider] = useState(0);
  const { title, items: estimationSteps = [] } = content ?? {};
  const handleStepChange = index => {
    return () => {
      setCurrentStep(index);
      if (slider) {
        slider.slickGoTo(index);
      }
    };
  };

  const appContext = useContext(AppContext);
  const isTablet = appContext.displayType.get() !== "desktop";

  const handleScrollToForm = () => {
    const elementToScroll = document.getElementById("request-form");
    if (elementToScroll) {
      window.scroll({
        top: elementToScroll.offsetTop,
        behavior: "smooth",
      });
    }
  };

  const getSliderSettings = () => {
    let settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 6,
      slidesToScroll: 1,
      slidesPerRow: 1,
      arrows: false,
      initialSlide: currentStep,
    };

    if (isTablet) {
      return (settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        slidesPerRow: 1,
        arrows: true,
        initialSlide: currentStep,
        afterChange: index => {
          if (currentStep === index) return;
          setCurrentStep(index);
        },
      });
    }

    return settings;
  };

  return (
    <div className={"n-skilled-team"}>
      <Img className={"n-skilled-team-line"} src={"/assets/images/homepage/SkilledTeam/line.svg"} alt="Image" />
      <UContainer>
        <div className={"skilled-team"}>
          <h2>Get the Perfectly-skilled Team for Your Software Development Services</h2>
          <div className="steps">
            <div className="steps-step">
              <Img src={"/assets/images/homepage/SkilledTeam/up_to_7_days.svg"} alt="Image" />
            </div>

            <div className="steps-step">
              <div className="step-block">
                <div className="block-one">
                  <Img src={"/assets/images/homepage/SkilledTeam/2days.svg"} alt="Image" />
                </div>
                <div className="block-two">
                  <h3>Step 1</h3>
                  <Img src={"/assets/images/homepage/SkilledTeam/daysTwo.svg"} alt="Image" />
                </div>
              </div>
              <p>
                You tell us more about your priorities and expectations for software development services to shape a
                vision of a design and development team perfect for your project.
              </p>
            </div>

            <div className="steps-step">
              <div className="step-block">
                <div className="block-one">
                  <Img src={"/assets/images/homepage/SkilledTeam/2days2.svg"} alt="Image" />
                </div>
                <div className="block-two">
                  <h3>Step 2</h3>
                  <Img src={"/assets/images/homepage/SkilledTeam/daysThree.svg"} alt="Image" />
                </div>
              </div>
              <p>
                We search within our software development agency talent pool. If there&apos;s a match, we can start the
                next day. In 60% of cases, we begin in less than 7 days.
              </p>
            </div>

            <div className="steps-step">
              <div className="step-block">
                <div className="block-one">
                  <Img src={"/assets/images/homepage/SkilledTeam/3days.svg"} alt="Image" />
                </div>
                <div className="block-two">
                  <h3>Step 3</h3>
                  <Img src={"/assets/images/homepage/SkilledTeam/daysTwo.svg"} alt="Image" />
                </div>
              </div>
              <p>
                All specialists in the team receive their scope of work with the deadlines, and the development process
                begins!
              </p>
            </div>
          </div>

          {/*Button Get estimation*/}
          {/*<UButton className={"skilled-team-button"} onClick={handleScrollToForm}>*/}
          {/*  Get estimation*/}
          {/*</UButton>*/}
        </div>

        <div className={"project-cost"}>
          <div className={"project-cost-content"}>
            <h2>Find Out Your Project Cost</h2>
            <p>We’ll contact you within one business day (GMT +2).</p>
            <UButton className={"project-cost-button"} onClick={handleScrollToForm}>
              Get estimation
            </UButton>
          </div>
          <div className="image-torus">
            <Img src={"/assets/images/homepage/SkilledTeam/Torus.svg"} alt="Image" />
          </div>
          <div className="image-cheese">
            <Img src={"/assets/images/homepage/SkilledTeam/Cheese_Half.svg"} alt="Image" />
          </div>
          <div className="image-torus-half">
            <Img src={"/assets/images/homepage/SkilledTeam/Torus_Half.svg"} alt="Image" />
          </div>
          <div className="image-cone">
            <Img src={"/assets/images/homepage/SkilledTeam/Cone.svg"} alt="Image" />
          </div>
        </div>

        {
          <div className="estimation">
            <h2>{title}</h2>
            <div className="selector">
              {isTablet && (
                <div className="selector-responsive">
                  <div className="selector-responsive-block">
                    <div className="selector-responsive-block-slider">
                      <Slider {...getSliderSettings()} ref={slider => setSlider(slider)}>
                        {(estimationSteps || []).map(({ name }, index) => (
                          <div className={"selector-step"} key={index}>
                            {currentStep === index ? <p className={"step__active"}>{name}</p> : <p>{name}</p>}
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </div>
                  <div className="selector-responsive-line">
                    <div className="selector-responsive-icons">
                      {(estimationSteps || []).map(({ icon }, index) => (
                        <div className="selector-step" key={index} onClick={handleStepChange(index)}>
                          {currentStep === index ? (
                            <>
                              <div className="selector-icon-wrap__active">
                                <Img src={icon} alt="active-icon" lazy={false} className="active-icon" />
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="selector-icon-wrap">
                                <Img src={"/assets/images/homepage/SkilledTeam/OvalGrey.svg"} alt="Image" />
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              <div className="selector-line" />
              <div className="selector-steps">
                {(estimationSteps || []).map(({ name, icon }, index) => (
                  <div className={"selector-step"} key={index} onClick={handleStepChange(index)}>
                    {currentStep === index ? (
                      <>
                        <div className="selector-icon-wrap__active">
                          <Img src={icon} alt="active-icon" lazy={false} className="active-icon" />
                        </div>
                        <p className="step__active">{name}</p>
                      </>
                    ) : (
                      <>
                        <div className="selector-icon-wrap">
                          <Img src={"/assets/images/homepage/SkilledTeam/OvalGrey.svg"} alt="icon" />
                        </div>
                        <p>{name}</p>
                      </>
                    )}
                  </div>
                ))}
              </div>
              <div className="selector-list">
                <ul>
                  {(
                    (estimationSteps &&
                      estimationSteps[currentStep] &&
                      estimationSteps[currentStep].content &&
                      Array.isArray(estimationSteps[currentStep].content) &&
                      estimationSteps[currentStep].content) ||
                    []
                  ).map((value, index) => (
                    <li key={index}>{value.string}</li>
                  ))}
                </ul>
              </div>
              <div className="selector-button-wrap">
                <h3>For precise estimation we offer a Discovery Phase</h3>
                <Link to={"/"}>
                  <span>Discovery Phase</span>
                </Link>
              </div>
            </div>
          </div>
        }
      </UContainer>
    </div>
  );
};

export default NSkilledTeam;
