import React from "react";
import { Link } from "react-router-dom";
import IconArrowDown from "../UIcon/icons/IconArrowDown";
import { BlogArticle } from "../BlogArticle";
import "./blog-articles-section.scss";
import "./blog-articles-section-mob.scss";

export default function BlogArticlesSection({ items, className, lazyImg }) {
  items = items.slice(0, 3);

  return (
    <section className={`blog-articles-section ${className || ""}`}>
      <div className="blog-articles-section__head">
        <h2 className="blog-articles-section__title">Insights</h2>
        <Link to="/blog" className="blog-articles-section__more  _desc">
          <span>More insights</span>
          <IconArrowDown />
        </Link>
      </div>
      <div className="blog-articles-section__list">
        {((Array.isArray(items) && items) || []).map((article, key) => (
          <BlogArticle lazyImg={lazyImg} article={article} key={key} className="blog-articles-section__item" />
        ))}
      </div>

      <Link to="/blog" className="blog-articles-section__more  _mob">
        <span>More insights</span>
        <IconArrowDown />
      </Link>
    </section>
  );
}
