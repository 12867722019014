import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { setBlogSSR, getBlogList } from "../../../../actions";
import UContainer from "../../../../components/UContainer/UContainer";
import "./NInsights.scss";
import BlogArticlesSection from "../../../../components/BlogArticlesSection/BlogArticlesSection";

class NInsight extends PureComponent {
  componentDidMount() {
    if (!this.props.isBlogSSR) {
      this.props.getBlogList(1);
    }
  }

  render() {
    const { blogList } = this.props;

    return (
      <UContainer className="n-insights">
        <BlogArticlesSection items={blogList} lazyImg={true} />
      </UContainer>
    );
  }
}

const mapStateToProps = ({ blog }) => {
  return {
    isBlogSSR: blog.isBlogSSR,
    blogList: blog.blogList,
    blogListTotal: blog.blogListTotal,
  };
};

const mapDispatchToProps = {
  setBlogSSR,
  getBlogList,
};

export default connect(mapStateToProps, mapDispatchToProps)(NInsight);
