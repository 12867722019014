import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import metaData from "../../constants/meta";
import { getHomeContent, setHomeSSR } from "../../actions";

import NProud from "./Sections/NProud";
import Img from "../../components/Img/Img";
import NPowerful from "./Sections/NPowerful";
import NInsight from "./Sections/NInsights";
import NPartners from "./Sections/NPartners";
import NSkilledTeam from "./Sections/NSkilledTeam";
import NCaseStudies from "./Sections/NCaseStudies";
import NCardsBottom from "./Sections/NCardsBottom";
import VideoModal from "../../components/VideoModal";
import ModalDialog from "../../components/ModalDialog";
import UContainer from "../../components/UContainer/UContainer";
import canonicalDomains from "../../constants/canonicalDomains";
import FirstSectionAnimation from "./Parts/FirstSectionAnimation";
import NClientsAbout from "./Sections/NClientsAbout/NClientsAbout";
import UPageContainer from "../../components/UPageContainer/UPageContainer";
import RequestSection from "../../sharedComponents/RequestSection/RequestSection";
import UPreloader from "../../components/UPreloader/UPreloader";
import ExpertServices from "./Sections/ExpertServices";

import "./homepage.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HomePage = ({ ...props }) => {
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { isHomeSSR, getHomeContent, setHomeSSR } = props;
  const toggleVideoModal = () => {
    setVideoModalOpen(!videoModalOpen);
  };
  const loadPageContent = async () => {
    setIsLoading(true);
    await getHomeContent();
    setIsLoading(false);
  };

  useEffect(() => {
    if (isHomeSSR) {
      setHomeSSR(false);
    } else {
      loadPageContent();
    }
  }, []);

  const { estimation_project_group, reasons_proud_group, industries, reviews, partners, awards } = props.content;

  return (
    <div className="homepage">
      <Helmet>
        <title>{metaData.main.title}</title>
        <meta name="description" content={metaData.main.description} />
        <meta name="keywords" content={metaData.main.keywords} />
        <meta name="H1" content={metaData.main.h1} />
        {(canonicalDomains || []).map(domain => (
          <link href={domain.link} rel={domain.rel} hrefLang={domain.hreflang} key={domain.link} />
        ))}
      </Helmet>
      <UPageContainer>
        <UContainer className="first-section">
          <div className="top-section">
            <div className="centered-section">
              <h1>Software Development Company in the UK</h1>
              <p className="description">
                Leading custom software development company in the UK delivering innovative solutions
              </p>
              <div className="buttons-wrapper">
                <Link to="/contact" className="buttons-first-section ourWorksBtn">
                  Let&apos;s Do This!
                </Link>
                <button className="buttons-first-section youtubeLinkBtn" onClick={toggleVideoModal}>
                  <span>Watch Overview</span>
                </button>
              </div>
            </div>
            {props.deviceWidth < 1470 ? (
              <div className={"first-section-image"}>
                <Img lazy={false} alt="ultimate" src="/assets/images/homepage/3d-2.png" />
              </div>
            ) : (
              <FirstSectionAnimation />
            )}
          </div>
        </UContainer>

        <NCardsBottom />

        <ModalDialog isShow={videoModalOpen} onClose={toggleVideoModal}>
          <VideoModal />
        </ModalDialog>
      </UPageContainer>

      {isLoading ? <UPreloader inline /> : <NSkilledTeam content={estimation_project_group} />}

      <NPartners partnersList={partners} />

      {isLoading ? (
        <UPreloader inline />
      ) : (
        <NProud className="homepage__proud" content={reasons_proud_group} industries={industries} awards={awards} />
      )}

      <UContainer>
        <NCaseStudies className="homepage__cases" />
      </UContainer>

      <NClientsAbout reviewsList={reviews} />

      <NPowerful />

      <ExpertServices />

      <NInsight />

      <RequestSection sourceName="Home page" />
    </div>
  );
};

const mapStateToProps = ({ home, caseStudies }) => {
  return {
    content: home.content,
    isHomeSSR: home.isHomeSSR,
    caseList: caseStudies.caseList,
  };
};

const mapDispatchToProps = {
  getHomeContent,
  setHomeSSR,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
