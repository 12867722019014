import React from "react";
import UContainer from "../../../../components/UContainer/UContainer";

import "./ExpertServices.scss";

const ExpertServices = () => {
  return (
    <section className="expert-services">
      <UContainer>
        <h2 className="expert-services__title">Expert Software Development Services in the UK</h2>
        <h3 className="expert-services__sub-title">Let Us Build Your Next Project</h3>
        <div className="expert-services__body">
          <p className="expert-services__text">
            As a custom software development company Powercode offers a range of services, including &nbsp;
            <a
              href="https://pwr-code.com/services/web"
              className="expert-services__link"
              target="_blank"
              rel="noopener noreferrer"
            >
              web
            </a>
            &nbsp; and
            <a
              href="https://pwr-code.com/services/mobile"
              className="expert-services__link"
              target="_blank"
              rel="noopener noreferrer"
            >
              &nbsp;mobile app development
            </a>
            , software integration,&nbsp;
            <a
              href="https://pwr-code.com/services/design"
              className="expert-services__link"
              target="_blank"
              rel="noopener noreferrer"
            >
              product design
            </a>
            , and digital transformation. We use the latest technologies and agile development methodologies to ensure
            that our clients receive the best solutions that are tailored to their&nbsp;
            <a
              href="https://pwr-code.com/services/mvp"
              className="expert-services__link"
              target="_blank"
              rel="noopener noreferrer"
            >
              business goals
            </a>
            .
          </p>
          <p className="expert-services__text">
            Our team understands that each project is unique, and we work closely with our clients to ensure that their
            vision is translated into reality. Our goal is to build long-term relationships with our clients by
            delivering&nbsp;
            <a
              href="https://pwr-code.com/services/qa"
              className="expert-services__link"
              target="_blank"
              rel="noopener noreferrer"
            >
              high-quality solutions&nbsp;
            </a>
            and exceptional customer service.
          </p>
        </div>
      </UContainer>
    </section>
  );
};

export default ExpertServices;
