import React from "react";
import { Link } from "react-router-dom";

import UContainer from "../../../../components/UContainer/UContainer";

import AwardsNRecognitions from "../AwardsNRecognitions/AwardsNRecognitions";

import "./NProud.scss";
import Img from "../../../../components/Img/Img";

const industiresLabelToPath = {
  Ecommerce: "ecommerce",
  Foodtech: "foodtech",
  Healthcare: "healthcare",
  Education: "education",
  Entertainment: "entertainment",
  "Real estate": "realestate",
  "Enterprise solutions": "enterprise",
  Hospitality: "hospitality",
};
const NProud = ({ className, content, industries, awards }) => {
  const { button = {}, content: textBody = [], title, title_industries_work } = content ?? {};

  return (
    <div className={`n-proud  ${className || ""}`}>
      <UContainer>
        <div className="n-proud-content">
          <div className="n-proud-content-left">
            <h2>{title}</h2>
            {(textBody || []).map((item, index) => (
              <p key={index} className={index === textBody.length - 1 ? "last" : ""}>
                {item.string}
                <br />
                <br />
              </p>
            ))}
            <div className="n-proud-content-left-about">
              {button.url && (
                <Link to={button.url} target={button.target || "_blank"}>
                  <span>
                    {button.title} &nbsp; &nbsp;
                    <Img src={"/assets/images/homepage/expand_more_24px.svg"} alt="Image" />
                  </span>
                </Link>
              )}
            </div>
          </div>
          <div className="n-proud-content-right">
            <h3>{title_industries_work}</h3>
            <div style={{ display: "flex" }}>
              <div className="n-proud-content-right-list">
                {(industries || []).map(({ icon, label, id }) => (
                  <div className="n-proud-content-right-list-item" key={id}>
                    <Img src={icon} alt="Image" />
                    {industiresLabelToPath[label] ? (
                      <Link to={`expertise/${industiresLabelToPath[label]}`}>{label}</Link>
                    ) : (
                      <p>{label}</p>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <Img className="n-proud-content-right-image" src={"/assets/images/homepage/rock.svg"} alt="rock" />
          </div>
        </div>
        <AwardsNRecognitions awards={awards} />
      </UContainer>
    </div>
  );
};

export default NProud;
