import React, { useState, useLayoutEffect } from "react";
import ReactPlayer from "react-player/lazy";
import PlayButton from "../PlayButton/PlayButton";

import "./VideoPlayer.scss";

/* eslint-disable no-unused-vars */
export default function VideoPLayer({ currentSlideId, poster, src, aspectRatio, variant, ...restProps }) {
  const [isPlayVideo, setVideoStatus] = useState(false);

  const afterPlayVideo = () => {
    setVideoStatus(true);
  };

  const stopVideo = () => {
    setVideoStatus(false);
  };

  useLayoutEffect(() => {
    stopVideo();
  }, [currentSlideId]);

  const aspectRatioProps = {
    width: "100%",
    height: "100%",
  };

  return (
    <div className={`video_player ${aspectRatio ? "video_player--aspect_ratio" : ""}`}>
      {aspectRatio && (
        <svg className="video_player-aspect_ration" viewBox={`0 0 ${aspectRatio.split(":").join(" ")}`} />
      )}
      <ReactPlayer
        url={src}
        onPlay={afterPlayVideo}
        playing={isPlayVideo}
        playIcon={<PlayButton as="div" variant={variant} />}
        {...restProps}
        {...(aspectRatio ? aspectRatioProps : {})}
      />
    </div>
  );
}
