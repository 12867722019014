import React from "react";

import "./FirstSectionAnimation.scss";

const FirstSectionAnimation = () => {
  return (
    <div className={"first-section-animation"}>
      <div className="gradient" />
      <div className="wrapper-vertical-phone">
        <img src={"/assets/images/homepage/animation/vertical-phone.png"} className="vertical-phone" alt="ultimate" />
      </div>
      <div className="wrapper-tablet">
        <img src={"/assets/images/homepage/animation/tablet.png"} alt="ultimate" className="tablet" />
      </div>
      <img
        src={"/assets/images/homepage/animation/angle-phone-shadow.png"}
        alt="ultimate"
        className="angle-phone-shadow"
      />
      <img src={"/assets/images/homepage/animation/angle-phone.png"} alt="ultimate" className="angle-phone" />
      <img src={"/assets/images/homepage/animation/stylus.svg"} alt="ultimate" className="stylus" />
      <img src={"/assets/images/homepage/animation/stylus-shadow.png"} alt="ultimate" className="stylus-shadow" />
      <img src={"/assets/images/homepage/animation/figure-cube.svg"} alt="ultimate" className="figure-cube" />
      <img src={"/assets/images/homepage/animation/figure-donut.png"} alt="ultimate" className="figure-donut" />
      <img
        src={"/assets/images/homepage/animation/figure-semicircle.png"}
        alt="ultimate"
        className="figure-semicircle"
      />
      <img src={"/assets/images/homepage/animation/tooltip-health.png"} alt="ultimate" className="tooltip-health" />
      <img src={"/assets/images/homepage/animation/tooltip-steps.png"} alt="ultimate" className="tooltip-steps" />
      <img src={"/assets/images/homepage/animation/tooltip-week.png"} alt="ultimate" className="tooltip-week" />
      <div className="wrapper-overflow-notebook">
        <div className="wrapper-notebook">
          <img src={"/assets/images/homepage/animation/notebook.png"} alt="ultimate" className="notebook" />
        </div>
      </div>
      <img src={"/assets/images/homepage/animation/emergency-help.png"} alt="ultimate" className="emergency-help" />
      <img src={"/assets/images/homepage/animation/symptoms.png"} alt="ultimate" className="symptoms" />
    </div>
  );
};

export default FirstSectionAnimation;
