import React, { useContext } from "react";
import { AppContext } from "../../../../AppHooks";

import UContainer from "../../../../components/UContainer/UContainer";
import Slider from "react-slick";

import "./NPartners.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Img from "../../../../components/Img/Img";

const NPartners = ({ partnersList }) => {
  const appContext = useContext(AppContext);
  const isMobile = appContext.displayType.get() === "mobile";
  const isTablet = appContext.displayType.get() !== "desktop";

  const getSliderSettings = () => {
    let settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      slidesPerRow: 2,
      arrows: true,
    };

    if (isMobile) {
      return (settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        slidesPerRow: 1,
        arrows: true,
      });
    }

    if (isTablet) {
      return (settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        slidesPerRow: 1,
        arrows: true,
      });
    }

    return settings;
  };

  return (
    <div className="n-partners">
      <UContainer>
        <div className="n-partners-block">
          <h2 className="n-partners-header">Our Partners:</h2>
          <div className="n-partners-list">
            {isTablet ? (
              <Slider {...getSliderSettings()}>
                {((Array.isArray(partnersList) && partnersList) || []).map((item, index) => (
                  <div className={"n-partners-partner-wrap"} key={index}>
                    {/* eslint-disable react/jsx-no-target-blank */}
                    <a href={item.acf.link} target={"_blank"} rel={item.acf.link_type === "dofollow" ? "" : "nofollow"}>
                      <Img src={item.acf.image} className={item.acf.caption ? "with-caption" : ""} alt="Image" />
                    </a>
                  </div>
                ))}
              </Slider>
            ) : (
              <>
                {((Array.isArray(partnersList) && partnersList) || []).map((item, index) => (
                  <div className={"n-partners-partner-wrap"} key={index}>
                    {/* eslint-disable react/jsx-no-target-blank */}
                    <a href={item.acf.link} target="_blank" rel={item.acf.link_type === "dofollow" ? "" : "nofollow"}>
                      <div className="item">
                        <Img src={item.acf.image} className={item.acf.caption ? "with-caption" : ""} alt="Image" />
                        <span>{item.acf.caption}</span>
                      </div>
                    </a>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
        {/*<div className={"n-partners-slider"}>*/}
        {/*  <Slider {...settings}>*/}
        {/*    {sliderData.map((item, index) => (*/}
        {/*      <div className={"n-partners-slider-content"} key={index}>*/}
        {/*        <p>{item.text}</p>*/}
        {/*        <div className={"n-partners-slider-name"}>*/}
        {/*          <div className={"n-partners-slider-name-wrap"}>*/}
        {/*            <LazyLoadImage src={item.icon} alt="Image" />*/}
        {/*          </div>*/}
        {/*          <p>{item.name}</p> <span>|</span> <p>{item.position}</p>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    ))}*/}
        {/*  </Slider>*/}
        {/*</div>*/}
      </UContainer>
    </div>
  );
};

export default NPartners;
